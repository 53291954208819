<template>
    <div class="col-md-12 classWellWl buyerwishlist" style="background: white;">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 wl-prd-search">
                  <input type="text" @keyup="searchList()" v-model="searchtxt" class="form-control" id="input-store" placeholder="Search Product" value="" name="voucher">          
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12" style="margin-left:-10px;">
                  <h2 class="title">Wishlist</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="row" >
          <div class="col-lg-12 col-md-12 col-sm-12" id="preview-area" v-if="blockLoading">
              <div class="row text-center">
                  <div class="sk-chase">
                      <div class="sk-chase-dot"></div>
                      <div class="sk-chase-dot"></div>
                      <div class="sk-chase-dot"></div>
                      <div class="sk-chase-dot"></div>
                      <div class="sk-chase-dot"></div>
                      <div class="sk-chase-dot"></div>
                  </div>
              </div>
            </div>
            <div>
              <div id="table-content" class="col-md-12 col-sm-12">
                <div id="table-content" class="col-sm-12" style="padding-right: 0px; padding-left: 0px;">
                  <table class="table table-striped col-md-12" id="wishTable">
                    <tbody>
                        <tr>
                            <td colspan="4" class="text-center col-md-12" v-if="blockLoading==false && wishlist.length==0">
                              No Records Found!
                            </td>
                        </tr>
                        <tr v-for="wl in wishlist" :key="wl._id">
                            
                        <td class="text-center col-md-1"><a><img width="70px" :src="wl.show_image[0].image" alt="Aspire Ultrabook Laptop" title="Aspire Ultrabook Laptop" class="img-thumbnail"></a></td>
                        
                        <td class="text-left col-md-5 col-sm-5 col-xs-5 wl-prd-desc">
                            
                            <div class="row">
                              <div class="col-md-12">								
                                <a><b>{{ wl.product_name }}</b></a>
                              </div>
                            </div>		
                            <div class="row">
                              <div class="col-md-12">								
                                <B>Category Name :</B> {{ wl.category.category_name }}
                              </div>
                            </div>
                            <div class="row">
                              
                              <div class="col-md-12">								
                                <B>Brand Name :</B> {{ wl.brand.brand_name }}
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">								
                                <B>Size :</B> Small
                              </div>
                            </div>

                        </td>
                        <td class="text-left col-md-2 col-sm-2 col-xs-2" style="vertical-align: middle;">
                          <div class="row">
                            <div class="col-md-12">
                              <B>Price Rs :</B> {{ wl.simple_products[0].special_price != 0 ? wl.simple_products[0].special_price.toFixed(2) : wl.simple_products[0].price.toFixed(2) }}
                            </div>
                          </div>
                        </td>
                        <td class="text-left col-md-4 col-sm-4 col-xs-4 wl-action" style="vertical-align: middle;">
                          <div class="row">
                            <div class="action">
                              <b-button size="sm" class="btn btn-danger" @click="removeFromWishList(wl._id)"><i class="fa fa-trash"></i></b-button>
                              <input  type="button" @click="cartPopUp(wl._id);" :disabled="wl.simple_products[0].quantity === 0" class="btn btn-mega btn btn-primary btn-sm buyerprof-sbtn" style="" value="ADD TO CART">
                            </div>
                          </div>
                        </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="row text-right" style="margin-bottom: -20px; margin-top: -40px;">
            <div class="col-sm-12">
              <b-pagination 
                v-model="currentPage" 
                ref="paginationCurrentPage1"
                :per-page="pageSize"
                :total-rows="rowcount" 
                align="right">
              </b-pagination>
            </div>
          </div>
                <!-- </div> -->
            <!-- </div> -->
		<!-- </div> -->

         <!-- Modal -->
    <div class="modal fade" id="productModalCenter" tabindex="-1" role="dialog" aria-labelledby="productModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
            <div class="modal-header" style="display: flex; justify-content: space-between; align-items: end;">
                <h5 class="modal-title" id="productModalLongTitle">Product Quick View</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="margin-top:-20px;">
                <span style="font-size: 30px; font-weight: initial; color: black; color: black !important;" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body"> 
                <ProductModalContent v-bind:pid="product_id" />
            </div>
            <div class="modal-footer">
                <button type="button" style="background-color: white !important; border: 1px solid black !important; border-radius: 5px; color: black;" class="btn" data-dismiss="modal">Close</button>
                <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
            </div>
            </div>
        </div>
    </div> 


    </div>
</template>
<script>
import APIProduct from "@/mixins/buyer/APIProduct";

import ProductModalContent from '@/components/product/product_modal.vue';

export default {
  name: 'wishlist',
  mixins: [APIProduct],
  components: {
    ProductModalContent
  },
  data() {
    return {
      blockLoading: true,
      pageSize: 5,
      currentPage: 1,
      conditionCp: 1,
      rowcount: 0,
      sliceValue: "",
      breadcrumb: [
        {
          text: "Wishlist",
          active: true
        }
      ],
      wishlist:[],
      dataobj: [],
      product_id: "",
      searchtxt: ''
    }
  },
  watch: {
    currentPage: function(val) {
      if (val) {
        this.wishlist = [];
        this.blockLoading = true;
        this.setWishList();
        this.paginationStyleFunction(this.$refs.paginationCurrentPage1.$refs.ul.children);
      }
    }
  },
  created: function() {
    this.handleBreadcrumb();
    this.setWishList();
  },
  mounted() {
    this.paginationStyleFunction(this.$refs.paginationCurrentPage1.$refs.ul.children);
  },
  methods: {
    paginationStyleFunction(array) {
      if (array === "") {
        array = this.$refs.paginationCurrentPage1.$refs.ul.children;
      }
      if (this.conditionCp !== this.currentPage) {
        for (let x = 0; x < array.length; x++) {
          if (array[x].innerText === this.conditionCp.toString()) {
            array[x].children[0].style.border = "";
            array[x].children[0].style.background = "";
            array[x].children[0].style.color = "black";
          }
        }
        this.conditionCp = this.currentPage;
      }
      for (let x = 0; x < array.length; x++) {
        if (array[x].innerText === this.currentPage.toString()) {
          array[x].children[0].style.border = "2px solid #337ab7";
          array[x].children[0].style.background = "#337ab7";
          array[x].children[0].style.color = "black";
        }
      }
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    setWishList: async function() {
      try {
        this.dataobj = await this.getWishList();
        console.log("this.dataobj",this.dataobj);
        this.searchList();
      } catch (error) {
          throw error;
      } 
    },
    cartPopUp: async function(pid) {
      this.product_id = pid;
      $('#productModalCenter').modal('show');
    },
    removeFromWishList: async function(item_id) {
      try {
        let wishlistItem = {product_id:item_id,status:2}
        let response = await this.saveWishliat(wishlistItem);
        for (var i = 0; i < this.wishlist.length; i++) {
          if(this.wishlist[i]._id === item_id){
            this.wishlist.splice(i, 1);
          }
        }
        this.$swal.fire({ position: 'center', type: 'success', title: 'Product Removed from Wishlist', showConfirmButton: false, timer: 1500 })
      } catch (error) {
        throw error;
      } 
    },
    searchList() {
      if(this.searchtxt !== '') {
        let self = this;
        var objfilter =  this.dataobj.filter(function(data) {
          if(data.product_name.toLowerCase().includes(self.searchtxt.toLowerCase())){
            return data;
          }
        });
        this.wishlist = objfilter;
      } else {
        this.wishlist = this.dataobj;

      }
      this.rowcount = this.wishlist.length;
      this.sliceValue = this.currentPage * 5;
      this.sliceValue = this.sliceValue - 5;
      this.wishlist = this.wishlist.slice(this.sliceValue, this.sliceValue + 5);
      this.blockLoading = false;
    }
  }
}
</script>
<style>
#productModalLongTitle {
  font-size: 18px;
  color: black;
  width: fit-content;
}

.classWellWl{
  padding: 15px 2% 0px 2%;
  border: 0px solid rgb(221, 221, 221);
}
.panel-body{
  padding-left: 0px;
  padding-right: 0px;
}
.wl-prd-search{
  text-align: right;
}
.wl-prd-search input[type="text"]{
  display: inline-block;
  width: 50%;
}
.wl-prd-desc{
  font-size:14px;
  margin-bottom:5px;
}
.wl-action{
  text-align: right !important;
}
.wl-action .btn{
  margin-right: 10px;
}
.buyerwishlist .buyerprof-sbtn {
  background:#5779ae !important;
  color: #ffffff !important;
  border: none;
}
.buyerwishlist .buyerprof-sbtn:hover {
  color: #000000 !important;
}
@media screen and (max-width: 1280px){
  #table-content {
    overflow-x: scroll;
  }
  #wishTable {
    width: 1350px !important;
  }
  .img-thumbnail {
    max-width: 80px;
  }
}
@media screen and (max-width: 1200px){
  .wl-prd-search input[type="text"]{
    width: 100%;
  }
}
@media screen and (max-width: 992px){
  .main-left .modcontent{
    height: auto !important;
  }
  .content-aside .module{
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 675px){
  .wl-prd-desc{
    font-size: 13px;
  }
}
@media screen and (max-width: 625px){
  .classWellWl{
    border: none !important;
  }
  .title {
    margin-left: 10px;
  }
}
@media screen and (max-width: 525px){
  .wl-prd-desc .btn-sm{
    font-size: 10px !important;
    margin-right: 5px !important;
  }
  .wl-action .btn{
    font-size: 10px;
  }
}
@media screen and (max-width: 475px){
  .wl-action{
    text-align: center !important;
  }
  .wl-action .btn{
    margin-right: 0px;
    margin-bottom: 7px;
  }

}
</style>
